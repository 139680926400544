.on-home {
    @include media-breakpoint-up(lg) {
        .logo {
            .color {
                fill: #fff !important;
            }
        }
    }

}

.owl-stage-outer {
    overflow: hidden;

    .owl-stage {
        display: flex;
    }
}

.section-properties {
    .carousel-checkbox {
        position: relative;
        width: 160px;
        text-align: center;
        border: 2px solid $red;
        cursor: pointer;
        @extend .tran;

        i {
            color: $red;
            text-transform: uppercase;
            letter-spacing: 3px;
            font-weight: 700;
            background-color: transparent;
            display: block;
            padding: 11px 0;
            box-shadow: 1.95px 15.881px 38px 0px rgba(252, 44, 53, 0);
            font-style: normal;
            @extend .tran;

            &:hover {
                @extend .bs-red;
                background-color: $red;
                color: #fff;
            }
        }

        input {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &:checked+i {
                @extend .bs-red;
                color: #fff;
                background-color: $red;
            }
        }
    }


    .property-carousel {
        @extend .bs-gray;

        .owl- {
            &prev {
                display: none;
            }

            &next {
                border: 2px solid #e41b22;
                background-color: transparent;
                padding: 8px 22px;
                position: absolute;
                right: 9px;
                top: -97px;

                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
        }
    }

    .property-card {
        @extend .bs-gray;

        a {

            &:hover {
                @extend .bs-red;

                .card- {
                    &middle {
                        background-color: $red;

                        p {
                            color: #fff;

                            i {
                                color: #fff;
                            }
                        }

                        h6 {
                            color: #fff;
                        }
                    }

                    &bottom {
                        border: 2px solid $red;

                        .agent-logo {

                            filter: grayscale(1);
                        }

                        .price {
                            color: $red;
                        }
                    }
                }
            }
        }

        .card- {
            &top {
                position: relative;
                overflow: hidden;
                height: 200px;
                img {
                    object-fit: none;
                    object-position: 50% 50%;
                }
            }

            &middle {
                @extend .tran;
                background-color: #f5f5f5;

                p,
                h6,
                p i {
                    @extend .tran;
                }
            }

            &bottom {
                @extend .tran;
                border: 2px solid transparent;
                background-color: #fff;

                .agent-logo {
                    filter: grayscale(1);
                    margin-left: auto;
                    float: right;
                    max-width: 150px;
                    height: 52px;
                    @extend .tran;
                }

                .price {
                    @extend .tran;
                }

                ul {
                    .icon {
                        background-image: url('../img/sprite-property-card.png');
                        background-repeat: no-repeat;
                        display: inline-block;
                        vertical-align: -3px;
                        width: 27px;
                        height: 19px;
                        margin-right: 5px;

                        &-1 {
                            background-position: 0 0;
                        }

                        &-2 {
                            background-position: -37px 0;
                        }

                        &-3 {
                            background-position: -75px 0;
                        }
                    }
                }
            }
        }
    }
}

.section-enquire {
    padding: 50px 0;

    .owl-nav {
        text-align: left;

        @media (max-width: 767px) {
            text-align: center;
        }

        button {
            background-color: transparent;
            border: 2px solid $red;
            width: 34px;
            height: 34px;
            padding: 0;
            opacity: 0.5;
            @extend .tran;

            img {
                display: block;
                margin: auto;
            }

            &:not(:last-child) {
                margin-right: 15px;
            }

            &:hover {
                opacity: 1;
            }
        }
    }

    .enquire-card {
        background-color: #fff;
        padding: 50px 15px;
        position: relative;
        @extend .tran;
        @extend .bs-gray;

        img {
            filter: grayscale(1);
            opacity: 0.2;
            @extend .tran;
        }

        .go-to {
            border: 4px solid #f5f5f5;
            border-radius: 50%;
            background-color: $red;
            position: absolute;
            right: 15px;
            top: 15px;
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 12px;
            opacity: 0;
            visibility: hidden;
        }

        .label {
            color: #e1e1e1;
            @extend .tran;
        }

        &:hover {
            @extend .bs-red;

            img {
                filter: grayscale(0);
                opacity: 1;
            }

            .label {
                color: $d-gray;
            }

            .go-to {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .view-all {
        i {
            position: relative;
            left: 0;
            @extend .tran;
        }

        &:hover {
            color: $red;
            color: $red;

            i {
                left: 5px;
            }
        }
    }
}

.section-blog {
    background-color: #f3f3f3;
    padding: 100px 0;

    @media (max-width: 767px) {
        padding: 50px 0;
    }

    h4 {
        position: relative;

        &:before {
            content: '';
            width: 60px;
            height: 2px;
            background-color: $red;
            margin-bottom: 40px;
            display: block;

            @media (max-width: 767px) {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .blog-post {
        .content-area {
            background-color: #fff;
            padding: 45px 35px;

            .excerpt {
                i {
                    position: relative;
                    left: 0;
                    @extend .tran;
                }

                &:hover {
                    color: $red;

                    i {
                        left: 5px;
                    }
                }
            }
        }
    }

    .posts {
        a {
            display: block;
            padding: 25px 0 25px 15px;
            border-left: 1px solid #e8e8e8;

            &:hover {
                .title {
                    color: $red;
                }

                border-left-color: $red;
            }
        }
    }
}

.section-agencies {
    padding: 100px 0 0;

    .agencies-wrap {
        background-image: url('../img/agencies_bg.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100%;

        .list-agencies {
            li {
                margin-bottom: 25px;

                img {
                    @extend .bs-gray;
                    border-radius: 10px;
                }
            }
        }
    }
}

.section-trending {
    padding: 100px 0;
}
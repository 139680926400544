@charset "UTF-8";

// Color
// @import "~materialize-css/sass/components/color-variables";
// @import "~materialize-css/sass/components/color-classes";

// Variables;
// @import "~materialize-css/sass/components/variables";

// Reset
// @import "~materialize-css/sass/components/normalize";

// components
// @import "~materialize-css/sass/components/global";
// @import "~materialize-css/sass/components/badges";
@import "~materialize-css/sass/components/icons-material-design";
// @import "~materialize-css/sass/components/grid";
// @import "~materialize-css/sass/components/navbar";
// @import "~materialize-css/sass/components/typography";
// @import "~materialize-css/sass/components/transitions";
// @import "~materialize-css/sass/components/cards";
// @import "~materialize-css/sass/components/toast";
// @import "~materialize-css/sass/components/tabs";
// @import "~materialize-css/sass/components/tooltip";
// @import "~materialize-css/sass/components/buttons";
// @import "~materialize-css/sass/components/dropdown";
// @import "~materialize-css/sass/components/waves";
@import "~materialize-css/sass/components/modal";
// @import "~materialize-css/sass/components/collapsible";
// @import "~materialize-css/sass/components/chips";
// @import "~materialize-css/sass/components/materialbox";
@import "~materialize-css/sass/components/forms/forms";
// @import "~materialize-css/sass/components/table_of_contents";
// @import "~materialize-css/sass/components/sidenav";
// @import "~materialize-css/sass/components/preloader";
// @import "~materialize-css/sass/components/slider";
// @import "~materialize-css/sass/components/carousel";
// @import "~materialize-css/sass/components/tapTarget";
// @import "~materialize-css/sass/components/pulse";
@import "~materialize-css/sass/components/datepicker";
@import "~materialize-css/sass/components/timepicker";

.input-field>label {
    left: $grid-gutter-width/2;
    pointer-events: none;
}

.dropdown-content {
    padding-inline-start: 0;
}

.z-depth-1 {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.postfix {
    @extend .prefix;
    width: 1rem !important;
    right: $grid-gutter-width/2;
}

/* 24dp elevation */
.z-depth-5 {
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
        0 9px 46px 8px rgba(0, 0, 0, 0.12),
        0 11px 15px -7px rgba(0, 0, 0, 0.2);
}

/*

Dropdown Styling

*/

.dropdown-content {
    &:focus {
        outline: 0;
    }


    @extend .z-depth-1;
    background-color: $dropdown-bg-color;
    margin: 0;
    display: none;
    min-width: 100px;
    overflow-y: auto;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9999; // TODO: Check if this doesn't break other things
    transform-origin: 0 0;


    li {

        &:hover,
        &.active {
            background-color: $dropdown-hover-bg-color;
        }

        &:focus {
            outline: none;
        }

        &.divider {
            min-height: 0;
            height: 1px;
        }

        &>a,
        &>span {
            font-size: 14px;
            color: $dropdown-color;
            display: block;
            line-height: 1.5;
            padding: (($dropdown-item-height - 22) / 2) 7px;
        }

        &>span>label {
            top: 1px;
            left: 0;
            height: 18px;
        }

        // Icon alignment override
        &>a>i {
            height: inherit;
            line-height: inherit;
            float: left;
            margin: 0 24px 0 0;
            width: 24px;
        }


        clear: both;
        color: $off-black;
        cursor: pointer;
        min-height: $dropdown-item-height;
        line-height: 1.5rem;
        width: 100%;
        text-align: left;
    }
}

body.keyboard-focused {
    .dropdown-content li:focus {
        background-color: darken($dropdown-hover-bg-color, 8%);
    }
}

// Input field specificity bugfix
.input-field.col .dropdown-content [type="checkbox"]+label {
    top: 1px;
    left: 0;
    height: 18px;
    transform: none;
}

.dropdown-trigger {
    cursor: pointer;
}


.chip {
    &:focus {
        outline: none;
        background-color: $chip-selected-color;
        color: #fff;

        .close {
            color: #fff;
        }
    }

    display: inline-block;
    height: 32px;
    font-size: 13px;
    font-weight: 500;
    color: rgba(0, 0, 0, .6);
    line-height: 32px;
    padding: 0 12px;
    border-radius: 16px;
    background-color: $chip-bg-color;
    margin-bottom: $chip-margin;
    margin-top: 8px;
    margin-right: $chip-margin;

    >img {
        float: left;
        margin: 0 8px 0 -12px;
        height: 32px;
        width: 32px;
        border-radius: 50%;
    }

    .close {
        cursor: pointer;
        float: right;
        font-size: 16px;
        line-height: 32px;
        padding-left: 8px;
    }
}

.chips {
    border: none;
    border-bottom: 1px solid $chip-border-color;
    box-shadow: none;
    margin: $input-margin;
    min-height: 45px;
    outline: none;
    transition: all .3s;

    &.focus {
        border-bottom: 1px solid $chip-selected-color;
        box-shadow: 0 1px 0 0 $chip-selected-color;
    }

    &:hover {
        cursor: text;
    }

    .input {
        background: none;
        border: 0;
        color: #7b7b7b;
        display: inline-block;
        font-size: $input-font-size;
        height: $input-height;
        line-height: 32px;
        outline: 0;
        margin: 0;
        padding: 0 !important;
        width: 120px !important;
    }

    .input:focus {
        border: 0 !important;
        box-shadow: none !important;
    }

    // Autocomplete
    .autocomplete-content {
        margin-top: 0;
        margin-bottom: 0;
    }
}

// Form prefix
.prefix~.chips {
    margin-left: 3rem;
    width: 92%;
    width: calc(100% - 3rem);
}

.chips:empty~label {
    font-size: 0.8rem;
    transform: translateY(-140%);
}

// Side nav

.sidenav {
    position: fixed;
    width: $sidenav-width;
    left: 0;
    top: 0;
    margin: 0;
    transform: translateX(-100%);
    height: 100%;
    height: calc(100% + 60px);
    height: -moz-calc(100%); //Temporary Firefox Fix
    padding-bottom: 60px;
    background-color: $sidenav-bg-color;
    z-index: 999;
    overflow-y: auto;
    will-change: transform;
    backface-visibility: hidden;
    transform: translateX(-105%);

    @extend .z-depth-1;

    // Right Align
    &.right-aligned {
        right: 0;
        transform: translateX(105%);
        left: auto;
        transform: translateX(100%);
    }

    .collapsible {
        margin: 0;
    }


    li {
        float: none;
        line-height: $sidenav-line-height;

        &.active {
            background-color: rgba(0, 0, 0, .05);
        }
    }

    .divider {
        margin: ($sidenav-padding / 2) 0 0 0;
    }

    .subheader {
        &:hover {
            background-color: transparent;
        }

        cursor: initial;
        pointer-events: none;
        color: rgba(0, 0, 0, .54);
        font-size: $sidenav-font-size;
        font-weight: 500;
        line-height: $sidenav-line-height;
    }

}


// Touch interaction
.drag-target {

    // Right Align
    &.right-aligned {
        right: 0;
    }

    height: 100%;
    width: 10px;
    position: fixed;
    top: 0;
    z-index: 998;
}


// Fixed Sidenav shown
.sidenav.sidenav-fixed {

    // Right Align
    &.right-aligned {
        right: 0;
        left: auto;
    }

    left: 0;
    transform: translateX(0);
    position: fixed;
}

// Fixed Sidenav hide on smaller
@media #{$medium-and-down} {
    .sidenav {
        &.sidenav-fixed {
            transform: translateX(-105%);

            &.right-aligned {
                transform: translateX(105%);
            }
        }

        >a {
            padding: 0 $sidenav-padding;
        }

        .user-view {
            padding: $sidenav-padding $sidenav-padding 0;
        }
    }
}


.sidenav .collapsible-body>ul:not(.collapsible)>li.active,
.sidenav.sidenav-fixed .collapsible-body>ul:not(.collapsible)>li.active {
    background-color: $primary-color;

    a {
        color: $sidenav-bg-color;
    }
}

.sidenav .collapsible-body {
    padding: 0;
}


.sidenav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    height: 120vh;
    z-index: 997;
    display: none;
}
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900|Material+Icons');

/* UNIVERSAL */

body {
    font-family: 'Roboto', sans-serif;
}

.tran {
    transition: .3s ease all;
}

.bs-red {
    box-shadow: 1.95px 15.881px 38px 0px rgba(252, 44, 53, 0.17);
}

.bs-gray {
    box-shadow: 0px 40px 80px 0px rgba(31, 31, 31, 0.08);
}

$spaceamounts: (10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
    @each $side in $sides {
        .m#{str-slice($side, 0, 1)}-#{$space} {
            margin-#{$side}: #{$space}px !important;
        }

        .p#{str-slice($side, 0, 1)}-#{$space} {
            padding-#{$side}: #{$space}px !important;
        }
    }
}

a {
    @extend .tran;

    &:hover {
        text-decoration: none;
    }
}

input[type="submit"] {
    cursor: pointer;
}

button {
    cursor: pointer;

    &:focus {
        outline: 0;
        box-shadow: none;
    }
}

.text- {
    &white {
        color: #fff;
    }

    &l-gray {
        color: $l-gray;
    }

    &l-gray-2 {
        color: $l-gray-2;
    }

    &d-gray {
        color: $d-gray;
    }

    &red {
        color: $red;
    }

    &upper {
        text-transform: uppercase;
    }

    &capitalize {
        text-transform: capitalize;
    }
}

.fz- {
    &12 {
        font-size: 12px;
    }

    &14 {
        font-size: 14px;
    }

    &16 {
        font-size: 16px;
    }

    &18 {
        font-size: 18px;
    }

    &20 {
        font-size: 20px;
    }

    &22 {
        font-size: 22px;
    }

    &24 {
        font-size: 24px;
    }

    &26 {
        font-size: 26px;
    }

    &30 {
        font-size: 30px;
    }

    &32 {
        font-size: 32px;
    }
}

.fw- {
    &300 {
        font-weight: 300;
    }

    &400 {
        font-weight: 400;
    }

    &500 {
        font-weight: 500;
    }

    &700 {
        font-weight: 700;
    }

    &900 {
        font-weight: 900;
    }
}

.btn- {
    &red {
        background-color: $red;
        color: #fff;
        letter-spacing: 2px;
        font-size: 0.8rem;
        text-transform: uppercase;
        border: 1px solid $red;
        @extend .bs-red;
        @extend .tran;

        &:hover {
            background-color: transparent;
            color: $red;
        }
    }

    &red-invert {
        color: #fff;
        color: $red;
        letter-spacing: 2px;
        font-size: 0.8rem;
        text-transform: uppercase;
        background-color: transparent;
        border: 1px solid $red;
        @extend .tran;

        &:hover {
            background-color: $red;
            color: #fff;
            @extend .bs-red;
        }
    }
    
}

.d- {
    &table {
        display: table;
    }
}

.relative {
    position: relative;
}

/* HEADER */
.main-nav {
    background-color: #fff;
    z-index: 999;
    position: relative;
    border-bottom: 1px solid #f1f1f1;

    .navbar-brand {
        .logo {
            width: 138px;
            height: 40px;

            .color {
                fill: #191919;
            }
        }
    }

    .navbar-nav {
        .nav-link {
            color: #000;
            font-size: 14px;
            padding-right: .7rem;
            padding-left: .7rem;
            border-bottom: 1px solid transparent;

            &:hover {
                color: $red;
                border-bottom: 1px solid $red;
            }

            &:active,
            &:focus {
                color: #000;
                border-bottom: 1px solid $red;
            }
        }

        .show>.nav-link,
        .active>.nav-link,
        .nav-link.show,
        .nav-link.active {
            color: #000;
            border-bottom: 1px solid $red;
        }
    }


    @include media-breakpoint-down(md) {
        .navbar-nav {
            .nav-link {
                padding-right: .7rem;
                padding-left: .7rem;
                padding-top: 15px;
                padding-bottom: 15px;
            }
        }

    }

    // Medium devices (tablets, 768px and up)
    @include media-breakpoint-between(md, lg) {

        .navbar-nav {
            .nav-link {
                padding-right: .7rem;
                padding-left: .7rem;
                padding-top: 15px;
                padding-bottom: 15px;

            }
        }

    }

    &.on-home {
        border-bottom: 1px solid transparent;
        @include media-breakpoint-up(lg) {
            background-color: transparent;

            .navbar-nav {
                .nav-link {
                    color: #fff;
                }
            }
        }
    }
}


/* FOOTER */



footer {
    background-color: $d-gray;
    display: flex;

    .form-newsletter {
        .btn{
            border-bottom-width: 2px;
        }
    }

    .footer-logo {
        margin-bottom: 40px;
    }

    h6 {
        margin-bottom: 55px;
    }

    .footer {
        &-top {
            padding: 80px 0 50px 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }

        &-bottom {
            padding: 50px 0;
        }
    }

    .list- {
        &social {
            margin-top: 50px;

            li {
                &:not(:last-child) {
                    margin-right: 35px;
                }

                a {
                    &:hover {
                        color: $red;
                    }
                }
            }
        }

        &links {
            li {
                &:not(:last-child) {
                    margin-bottom: 15px;
                }

                a {
                    &:hover {
                        color: $red;
                    }
                }
            }
        }
    }
}

.search-icon {
    background-image: url('../img/search.png');
    background-repeat: no-repeat;
    background-position: center right 10px;
}
.banner-area {
    height: 540px;
    padding-top: 125px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    background-color: $brand-dark-gray;
    color: #fff;

    &.on-home {
        margin-top: -66px;
    }

    h1 {
        font-size: 30px;

        span {
            font-weight: 300;
        }
    }

    h2 {
        font-size: 26px;
        font-weight: 300;
        margin-bottom: 0px;
    }


    p {
        font-size: 16px;
    }

    &.banner-sm {
        height: 450px;
        padding-top: 180px;
        margin-bottom: 40px;

        &.with-tab-section {

            @include media-breakpoint-up(lg) {
                padding-top: 96px;
                height: 378px;
            }
        }

        h1 {

            font-size: 32px;
        }

        // @include media-breakpoint-up(lg) {

        //     padding-top: 225px;
        // }
    }
    
    &.banner-xs {
        height: 380px;
        padding-top: 140px;
        margin-bottom: 40px;
    }

    // Extra small devices (portrait phones, less than 576px)
    @include media-breakpoint-up(lg) {
        h1 {
            font-size: 46px;
        }


        h2 {
            font-size: 36px;
        }

        height: 642px;
        padding-top: 175px;

    }



    // Medium devices (tablets, 768px and up)
    @include media-breakpoint-between(md, lg) {

        height: 596px;

        h1 {
            font-size: 40px;
        }

        h2 {
            font-size: 30px;
        }


    }

}

.section-tab {
    margin-top: -146px;


    .tab-btn-section {
        .nav-tabs {
            border-bottom-color: transparent;

            .nav-link {
                background-color: rgba($brand-dark-gray, $alpha: .6);
                border-top: 2px solid rgba($brand-dark-gray, $alpha: .6);
                letter-spacing: 4px;
                border-color: transparent;
                color: #fff;
                font-weight: 500;
                padding: 15px 30px;
                font-size: 14px;
            }

            .nav-link.active,
            .nav-item.show .nav-link {
                border-top: 2px solid $primary;
                background-color: rgba($brand-dark-gray, $alpha: .9);
            }

        }

    }


    .tab-content-section {
        .content-top {
            background-color: rgba($brand-dark-gray, $alpha: .9);
            color: #fff;
            padding: 10px 30px 0px 30px;

            .input-field {
                >input[type=text] {
                    color: #fff;
                    border-color: #fff !important;

                    &:focus {
                        border-color: $red !important;
                    }
                }

                >label {
                    color: #fff;
                    margin-left: 0;
                }
            }

            .select-wrapper .caret {
                fill: #fff;
            }

            .select-wrapper input.select-dropdown {
                color: #fff;
                border-color: #fff
            }

            .chips {
                border-color: #fff;

                .input {
                    color: #fff;
                }
            }
        }

        .content-bottom {
            background-color: #fff;
            padding: 20px 30px;
        }

        .chips {
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-down(md) {

        .tab-btn-section {
            .nav-tabs {
                border-bottom-color: transparent;

                .nav-link {
                    letter-spacing: 2px;
                    padding: 15px 10px;
                    font-size: 13px;
                }

                .nav-link.active,
                .nav-item.show .nav-link {
                    border-top: 2px solid $primary;
                    background-color: rgba($brand-dark-gray, $alpha: .9);
                }

            }

        }
    }

    @include media-breakpoint-down(lg) {

        .tab-btn-section {
            .nav-tabs {
                border-bottom-color: transparent;

                .nav-link {
                    letter-spacing: 2px;
                    padding: 15px 15px;
                }

                .nav-link.active,
                .nav-item.show .nav-link {
                    border-top: 2px solid $primary;
                    background-color: rgba($brand-dark-gray, $alpha: .9);
                }

            }

        }
    }

    @include media-breakpoint-down(sm) {

        .tab-btn-section {
            .nav-tabs {
                border-bottom-color: transparent;

                .nav-link {
                    letter-spacing: 1px;
                    padding: 11px 8px;
                    font-size: 11px;
                }

                .nav-link.active,
                .nav-item.show .nav-link {
                    border-top: 2px solid $primary;
                    background-color: rgba($brand-dark-gray, $alpha: .9);
                }

            }

        }


        .tab-content-section {
            .content-top {
                background-color: rgba($brand-dark-gray, $alpha: .9);
                color: #fff;
                padding: 10px 30px 0px 30px;

                .select-wrapper .caret {
                    fill: #fff;
                }

                .select-wrapper input.select-dropdown {
                    color: #fff;
                    border-color: #fff
                }
            }

            .content-bottom {
                background-color: #fff;
                padding: 20px 30px;
            }

        }

    }
}


// Forms Extended from Materialize
.select-dropdown.dropdown-trigger {
    color: #7b7b7b;
}

.select-wrapper .caret {
    fill: #7b7b7b;
}

.worth-calculator {
    padding-top: 30px;

    @include media-breakpoint-up(md) {
        padding-top: 70px;
        box-shadow: 0px 28px 68px 0px rgba(77, 77, 77, 0.15);
        background-color: rgb(255, 255, 255);
        margin-top: -265px;
    }
}

// PAGINATION

.pagination-area {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;

    ul {
        margin-bottom: 0;

        li {

            &:first-child,
            &:last-child {
                a {
                    background-color: #f3f3f3;
                    color: $brand-lighten-gray;

                    &:hover {
                        background-color: #f3f3f3;
                        color: $d-gray;
                    }
                }
            }

            a {
                border: 0;
                border-radius: 0 !important;
                color: $d-gray;
                font-size: 14px;

                &:hover {
                    background-color: $red;
                    color: #fff;
                }

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }

    .pagination-form {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;

        input {
            width: 50px;
            margin: 0 10px;
            height: auto;
        }

        span {
            font-size: 12px;
            color: $brand-lighten-gray;
            text-transform: uppercase;
        }

        button {
            background-color: transparent;
            border: 0;
            color: $red;
            text-transform: uppercase;

        }
    }
}

.heading-lined {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:after {
        content: '';
        flex: 1 1 auto;
        height: 1px;
        background-color: #f1f1f1;
        margin-left: 15px;
    }
    &.dark {
        &:after {
            background-color: rgba(113, 113, 113,0.1);
        }
    }
}

.banner-stat {
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    background-size: 100% 100%;
    background-position: center center;
    padding: 5px 5px 5px 12px;
    position: absolute;
    top: 15px;
    left: 15px;

    &.red {

        background-image: url('../img/red-banner.png');
    }

    &.green {
        background-image: url('../img/green-banner.png');
    }

    &.black {
        background-image: url('../img/black-banner.png');
    }

    &.yellow {
        background-image: url('../img/yellow-banner.png');
    }

    &.center {
        left: 50%;
        transform: translateX(-50%);
    }
}

.featured-card {
    position: relative;

    .top {
        position: relative;

        .image {
            flex: 0 0 250px;
            background-size: cover;
            background-position: center center;
            height: 250px;
        }
    }

    .bottom {
        position: relative;
        display: flex;
        z-index: 5;
        justify-content: center;

        .card-content {
            margin-top: calc(-20%);
            background-color: #fff;
            @extend .shadow-lg;
        }
    }
}

.property-card {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #d2d2d2;

    &-wrap {
        &:not(:last-child) {
            margin-bottom: 35px;
        }
    }

    a {
        margin: 0 auto;
        width: 100%;
    }

    .image {
        flex: 0 0 285px;
        background-size: cover;
        background-position: center center;

        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
            height: 250px;
        }

    }

    .details {
        flex: 0 0 calc(100% - 285px);

        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
        }

        .top {
            .name-area {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                .name {
                    flex: 0 0 calc(100% - 105px);
                    margin-right: 15px;

                    @include media-breakpoint-down(lg) {
                        flex: 0 0 100%;
                        margin-right: 0;

                        h4 {
                            font-size: 18px;
                        }

                        p {
                            font-size: 12px;
                        }
                    }

                    @include media-breakpoint-down(sm) {
                        flex: 0 0 calc(100% - 105px);
                        margin-right: 15px;
                    }
                }

                .icon {
                    flex: 0 0 90px;

                    img {
                        width: 90px;
                        height: auto;
                    }

                    @include media-breakpoint-down(lg) {
                        margin: 12px auto 6px auto;
                    }
                }
            }

            .property-for {
                flex: 0 0 46px;
                height: 34px;
                display: inline-block;
                vertical-align: middle;
                background-size: cover;
                background-position: top center;

                &.sale {
                    background-image: url('../img/sale.png');
                }

                &.rent {
                    background-image: url('../img/rent.png');
                }
            }

            ul {
                @include media-breakpoint-down(lg) {
                    flex: 0 0 100%;
                }

                @include media-breakpoint-down(sm) {
                    flex: 0 0 auto;
                }

                .icon {
                    background-image: url('../img/sprite-property-card.png');
                    background-repeat: no-repeat;
                    display: inline-block;
                    vertical-align: -3px;
                    width: 27px;
                    height: 19px;
                    margin-right: 5px;

                    &-1 {
                        background-position: 0 0;
                    }

                    &-2 {
                        background-position: -37px 0;
                    }

                    &-3 {
                        background-position: -75px 0;
                    }
                }
            }
        }

        .bottom {
            ul {
                @include media-breakpoint-down(lg) {
                    flex: 0 0 100%;

                    .btn.btn-red-invert {
                        font-size: 12px;
                    }
                }

                @include media-breakpoint-only(sm) {
                    flex: 0 0 auto;
                }
            }
        }
    }

    &.is-vertical {
        .image {
            flex: 0 0 100%;
            height: 250px;
        }

        .details {

            flex: 0 0 100%;
        }
    }
}

.btn-flat {
    border-radius: $border-radius;
    border: none;
    padding: $btn-padding-y $btn-padding-x;
    text-transform: uppercase;
    vertical-align: middle;
    box-shadow: none;
    background-color: transparent;
    color: $primary;
    cursor: pointer;
    transition: background-color .2s;

    &:focus,
    &:hover {
        box-shadow: none;
    }

    &:focus {
        background-color: rgba(0, 0, 0, .1);
    }

    -webkit-tap-highlight-color: transparent; // Gets rid of tap active state
}



/* Text inputs overrides*/

input:not([type]),
input[type=text]:not(.browser-default),
input[type=password]:not(.browser-default),
input[type=email]:not(.browser-default),
input[type=url]:not(.browser-default),
input[type=time]:not(.browser-default),
input[type=date]:not(.browser-default),
input[type=datetime]:not(.browser-default),
input[type=datetime-local]:not(.browser-default),
input[type=tel]:not(.browser-default),
input[type=number]:not(.browser-default),
input[type=search]:not(.browser-default),
textarea.materialize-textarea {
    width: 1%;
    margin: 0 0 1px 0;
    display: block;
    flex: 1 1 auto;
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, border 0.3s ease-in-out;

    // Focused label style
    &:focus:not([readonly])+.input-group-append .input-group-text {
        transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, border 0.15s ease-in-out;
        color: $input-focus-color;
        border-bottom: 1px solid $input-focus-color;
        box-shadow: 0 1px 0 0 $input-focus-color;
    }
}

.input-field,
.select-wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    &.white {
        label {
            color: #fff;
        }
        input {
            border-color: #fff;
            color: #fff;
        }
    }
}

.input-field {
    .user {
        background-image: url('../img/user.png');
        background-repeat: no-repeat;
        background-position: center left 10px;
        padding-left: 35px !important;
        & + label {
            margin-left: 35px;
        }
    }
}

input[type=number]:not(.browser-default) {
    appearance: textfield;
    text-align: center;
}

.input-group-text {
    border-left: none;
    border-top: none;
    border-right: none;
    border-radius: none;
    margin-bottom: 1px;
}

.accordion {
    font-size: 12px;
    padding-bottom: 40px;

    h4 {
        margin-top: 40px;

        .btn {
            color: $body-color;
            font-weight: 300;
            font-size: 16px;

            &:hover,
            &:active,
            &:focus {
                background: none;
                border-color: transparent;
                color: $primary;
                box-shadow: none;
            }
        }
    }

    p {
        font-size: 12px;
        @extend .text-l-gray-2;
    }
}

// 

.list-trending {
    a {
        border-left: 1px solid #e8e8e8;
        display: table-cell;
        padding: 5px 0 5px 25px;

        &:hover {
            color: $red;
            border-left-color: $red;
        }
    }

    &.list-compact {
        a {
            padding: 2px 0 2px 25px;
        }
    }

    &.with-arrow {
        a {
            position: relative;
            border-left: none;
            @extend .tran;

            &:hover {
                &::before {
                    position: absolute;
                    left: -1px;
                    font-size: 12px;
                    content: ">";
                }
            }
        }
    }
}

.section-tabbed-listings {
    .nav-tabs {
        border-bottom-color: transparent;
        border-bottom: 1px solid #fff;

        .nav-link {
            background-color: $light;
            border-top: 2px solid $light;
            border-color: transparent;
            color: $body-color;
            padding: 12px 30px;
            font-size: 14px;

            span {
                color: $primary;
            }
        }

        .nav-link.active,
        .nav-item.show .nav-link {
            border-bottom: 1px solid #fff;
            border-top: 1px solid $primary;
            background-color: #fff;
        }

    }
}

.title-trimmed {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.list-social-share {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;

    li {
        flex: 1 1 auto;

        .social {
            width: 42px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            border-radius: 50px;

            &.fb {
                background-color: #3b5998;

                &:hover {
                    box-shadow: 4.635px 14.266px 32px 0px rgba(37, 83, 180, 0.25);
                }
            }

            &.gp {
                background-color: #d13338;

                &:hover {
                    box-shadow: 4.635px 14.266px 32px 0px rgba(209, 51, 56, 0.25);
                }
            }

            &.tw {
                background-color: #03c2f1;

                &:hover {
                    box-shadow: 4.635px 14.266px 32px 0px rgba(3, 194, 241, 0.25);
                }
            }

            &.li {
                background-color: #296384;

                &:hover {
                    box-shadow: 4.635px 14.266px 32px 0px rgba(41, 99, 132, 0.25);
                }
            }

            &.pt {
                background-color: #e82e35;

                &:hover {
                    box-shadow: 4.635px 14.266px 32px 0px rgba(232, 46, 53, 0.25);
                }
            }
        }
    }
}

.table-compare-listings {
    tbody {
        th {
            white-space: nowrap;
        }
    }
}

.table-brand {

    th,
    td {
        border: 2px solid #fff;
    }

    thead {

        background-color: #a2a6b7;
        color: #fff;

        th,
        td {
            border-bottom-width: 2 * $table-border-width;
        }
    }

    tbody {

        th,
        td {
            color: rgba(26, 26, 26, 0.6);
        }
    }

    tbody tr:nth-of-type(#{$table-striped-order}) {
        background-color: $table-accent-bg;
    }

}

.line {
    width: 50px;
    border-width: 0px;
    border-top-width: 2px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;

    &.solid {
        border-style: solid;
    }

    &.dashed {
        border-style: dashed;
    }

    &.green {
        border-color: $success-color;
    }

    &.red {
        border-color: $red;
    }
}

.owl-carousel {
    opacity: 0;
    visibility: hidden;

    &.owl-loaded {

        display: block;
        opacity: 1;
        visibility: visible;
    }
}

.modal-sm {
    max-width: 500px;
    width: 100%;
    max-height: 100%;
}

.popover {
    border: 0;
    border-radius: 0;
    @extend .shadow-lg;
}

.call-button {
    .text-2 {
        display: none;
    }
}
@import "variables";

@import "materialize";
@import "bootstrap";

@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";

// @import "owl"; I dont think we need it we are styling custom next and previous

@import 'default';

@import "common";


@import "home";
@import "inner";
@import "profiles";
@import "calculators";
@import "select2";
@import "lightgallery";
@import "intlTelInput";

.section-agencies,
.property-carousel {
    opacity: 0;
}

.section-agencies.owl-loaded,
.property-carousel.owl-loaded {
    opacity: 1;
}

/* Feel free to change duration  */
.animated {
    -webkit-animation-duration: 2000 ms;
    animation-duration: 2000 ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

/* .owl-animated-out - only for current item */
/* This is very important class. Use z-index if you want move Out item above In item */
.owl-animated-out {
    z-index: 1
}

/* .owl-animated-in - only for upcoming item
  /* This is very important class. Use z-index if you want move In item above Out item */
.owl-animated-in {
    z-index: 0
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

@-webkit-keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

.owl-dots.disabled,
.owl-nav.disabled {
    display: none
}

.property-compare {
    position: relative;

    .close {
        position: absolute;
        top: -11px;
        right: 13px;
        font-size: 14px;
        background-color: #fff;
        width: 25px;
        height: 25px;
        padding: 5px;
        border-radius: 50%;
        z-index: 55;
    }
}

.buy-vs-rent-calculator {
    input[type=number]:not(.browser-default){
        appearance: textfield;
        text-align: right;
    }
    .input-group-append {
        .input-group-text {
            min-width: 70px;
            text-align: center;
        }
    }
}
.section-mortgage{
    &.on-page {    
        margin-top: -150px;
    }
}
$red: #e41b22;
$l-gray-2: #949494;
$l-gray: #727272;
$d-gray: #1a1a1a;
$d-gray-2: #151515;
$brand-dark-gray: #1a1a1a;
$brand-light-gray: #7c7c7c;
$brand-lighten-gray: #c0c0c0;
$brand-lighten-gray-card: #949494;

$light: #f5f5f5;
$primary: $red;
$primary-color:$red;

$grid-columns: 24;
$grid-gutter-width: 15px;

$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;

$btn-padding-x: 1.5rem;
$btn-padding-x-sm: 1rem;
$btn-padding-x-lg: 2.25rem;
$btn-font-size-sm: 12px;

$btn-padding-y: .725rem;

$breadcrumb-bg: none;
$breadcrumb-item-padding: 20px;
$breadcrumb-divider: quote(">>");
$breadcrumb-padding-x: $grid-gutter-width / 2;
$breadcrumb-padding-y: 20px;

$line-height-base: 1.7;
// for material

$secondary-color:$red;
$error-color: $red;
$success-color: #28a745;
$component-active-bg :$red;
$input-btn-focus-color: $red;
$custom-select-focus-border-color :$red;

// Radio Buttons
$radio-fill-color: $secondary-color;
$radio-empty-color: #5a5a5a;
$radio-border: 2px solid $radio-fill-color;

// Range
$range-height: 14px;
$range-width: 14px;
$track-height: 3px;

// Select
$select-border: 1px solid #f2f2f2;
$select-background: rgba(255, 255, 255, 0.90);
$select-focus: 1px solid lighten($secondary-color, 47%);
$select-option-hover: rgba(0, 0, 0, .08);
$select-option-focus: rgba(0, 0, 0, .08);
$select-option-selected: rgba(0, 0, 0, .03);
$select-padding: 5px;
$select-radius: 2px;
$select-disabled-color: rgba(0, 0, 0, .3);


// Switches
$switch-bg-color: $secondary-color;
$switch-checked-lever-bg: desaturate(lighten($switch-bg-color, 25%), 25%);
$switch-unchecked-bg: #F1F1F1;
$switch-unchecked-lever-bg: rgba(0, 0, 0, .38);
$switch-radius: 15px;

$button-background-focus: lighten($red, 4%);
$select-focus: 1px solid lighten($red, 47%);
$input-height: 3rem;
$input-border-color: #969696cc;
$input-border: 1px solid $input-border-color;
$input-background: #fff;
$input-error-color: $error-color;
$input-success-color: $success-color;
$input-focus-color: $secondary-color;
$input-font-size: 14px;
$input-margin-bottom: 8px;
$input-margin: 0 0 $input-margin-bottom 0;
$input-padding: 0;
$label-font-size: .8rem;
$input-disabled-color: rgba(0, 0, 0, .42);
$input-disabled-solid-color: #949494;
$input-disabled-border: 1px dotted $input-disabled-color;
$input-invalid-border: 1px solid $input-error-color;
$input-icon-size: 0.8rem;
$placeholder-text-color: lighten($input-border-color, 20%);

$dropdown-bg-color: #fff;
$dropdown-hover-bg-color: #eee;
$dropdown-color: $secondary-color;
$dropdown-item-height: 50px;

$off-black: rgba(0, 0, 0, 0.87);
$gutter-width: 1rem;
$small-screen-up: 601px;
$medium-screen-up: 993px;
$large-screen-up: 1201px;
$small-screen: 600px;
$medium-screen: 992px;
$large-screen: 1200px;
$medium-and-up: "only screen and (min-width : #{$small-screen-up})";
$large-and-up: "only screen and (min-width : #{$medium-screen-up})";
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})";
$small-and-down: "only screen and (max-width : #{$small-screen})";
$medium-and-down: "only screen and (max-width : #{$medium-screen})";
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})";

$datepicker-display-font-size: 2.8rem;
$datepicker-calendar-header-color: #999;
$datepicker-weekday-color: rgba(0, 0, 0, .87);
$datepicker-weekday-bg: darken($secondary-color, 7%);
$datepicker-date-bg: $secondary-color;
$datepicker-year: rgba(255, 255, 255, .7);
$datepicker-focus: rgba(0, 0, 0, .05);
$datepicker-selected: $secondary-color;
$datepicker-selected-outfocus: desaturate(lighten($secondary-color, 35%), 15%);
$datepicker-day-focus: transparentize(desaturate($secondary-color, 5%), .75);
$datepicker-disabled-day-color: rgba(0, 0, 0, .3);

$timepicker-clock-color: rgba(0, 0, 0, .87);
$timepicker-clock-plate-bg: #eee;


// 7. Chips
// ==========================================================================

$chip-bg-color: #e4e4e4 !default;
$chip-border-color: #7b7b7b !default;
$chip-selected-color: $red;
$chip-margin: 5px !default;

$button-flat-color: #343434;
$button-flat-disabled-color: lighten(#999, 10%);

$input-border-radius: 0;
$input-group-addon-bg: transparent;

.no-select {
  user-select: none;
}

$tooltip-width: 40px;

$table-accent-bg: #f5f6f8;


$sidenav-width: 300px !default;
$sidenav-font-size: 14px !default;
$sidenav-font-color: rgba(0, 0, 0, .87) !default;
$sidenav-bg-color: #fff !default;
$sidenav-padding: 16px !default;
$sidenav-item-height: auto !default;
$sidenav-line-height: $sidenav-item-height !default;
$button-raised-color: #fff !default;
$button-raised-background: $secondary-color !default;
$button-raised-background-hover: lighten($button-raised-background, 5%) !default;
.four-features {
    background-color: #d7262c;
    padding: 40px 0;
    .background-text {
        font-size: 100px;
        color: rgba(0,0,0,0.15);
        font-weight: 900;
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        @include media-breakpoint-down(sm) {
            right: 0;
            margin: auto;
            text-align: center;
        }
    }
    .wrap {
        position: relative;
        min-height: 120px;
        p {
            padding: 75px 20px 0;
            position: relative;
            z-index: 2;
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }
    }
}

.list-checked {
    column-count: 4;
    @include media-breakpoint-down(md) {
        column-count: 3;
    }
    @include media-breakpoint-down(sm) {
        column-count: 2;
    }
    @include media-breakpoint-down(xs) {
        column-count: unset;
    }
    li {
        color: $l-gray-2;
        font-size: 14px;
        &:before {
            content: '\f00c';
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            color: $green;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
        }
    }
}

.about {
    .logo {
        margin-top: -50px;
        background-color: #fff;
        @include media-breakpoint-down(sm) {
            margin-top: 20px;
            background-color: transparent;
        }
    }
    .form {
        margin-top: -475px;
        margin-bottom: 80px;
        @media (max-width: 1400px) {
            margin-top: -440px;
        }
        @media (max-width: 1300px) {
            margin-top: -400px;
        }
        @include media-breakpoint-down(lg) {
            margin-top: 0;
            margin-bottom: 30px;
        }
        .wrap {
            background-color: rgba(0,0,0,0.6);
        }
    }
}

.photo-gallery {
    .gallery {
        img {
            border: 15px solid #fff;
            cursor: pointer;
        }
    }
}

.floor-plans {
    .owl-nav {
        position: absolute;
        top: 5px;
        right: 0;
        background-color: #fff;
        padding-left: 10px;
        button {
            border: 2px solid $red;
            width: 36px;
            height: 34px;
            background-color: transparent;
            opacity: .5;
            @extend .tran;
            &:hover {
                opacity: 1;
            }
            &.owl-next {
                margin-left: 10px;
            }
        }
    }
    .carousel-floor-plans {
        a {
            display: block;
            &:hover {
                @extend .shadow-lg;
            }
        }
    }
}

.banner-carousel {
    position: relative;
    .owl-dots {
        position: absolute;
        bottom: 30px;
        right: 60px;
    }
    .owl-dot {
        background-color: rgba(255,255,255,0.6);
        border: 0;
        padding: 0;
        width: 10px;
        height: 10px;
        border-radius: 50px;
        @extend .tran;
        &.active {
            background-color: $red;
        }
        &:not(:last-child) {
            margin-right: 5px;
        }
    }
}

#shareMessage {
    height: 100px !important;
}
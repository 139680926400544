.result-card {
  margin-bottom: 40px;
  background-color: #fff;
  height: calc(100% - 40px);

  a {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    border: 2px solid #f1f1f1;
    box-shadow: 0px 33px 62px 0px rgba(228, 27, 34, 0);
    @extend .tran;

    &:hover {
      border: 2px solid $red;
      box-shadow: 0px 33px 62px 0px rgba(228, 27, 34, 0.35);

      color: #fff;

      .bottom {
        border-top: 2px solid $red;
        background-color: $red;

        h6,
        p,
        i.fa {
          color: #fff;
        }

        .details {
          li {
            background-color: $red;

            p,
            span,
            i,
            i.fa {
              color: #fff;
            }

            &:not(:last-child) {
              border-right: 2px solid #d0171d;
            }
          }
        }
      }
    }
  }

  &.agent {
    padding-top: 85px;

    .top {
      .image {
        margin-top: -85px;

        img {
          @extend .shadow-lg;
        }
      }
    }
  }

  .top {
    padding: 15px 30px;

    .image {
      max-width: 170px;
      width: 100%;
      margin: auto;
      text-align: center;
    }

    .ratings {
      margin-bottom: 30px;
    }
  }

  .bottom {
    border-top: 2px solid #f1f1f1;
    background-color: #fff;
    margin-top: auto;
    @extend .tran;

    h6 {
      color: $d-gray;
      @extend .tran;
    }

    p {
      margin-bottom: 0;
      @extend .tran;
      color: $l-gray;
    }

    i.fa {
      color: $primary;
      @extend .tran;
    }

    .details {
      margin-bottom: 0;
      padding-left: 0;
      list-style-type: none;
      display: flex;

      li {
        flex: 1 1 auto;
        text-align: center;
        background-color: #fbfbfb;
        padding: 10px;
        @extend .tran;

        p {
          color: $l-gray;
          font-size: 26px;
          font-weight: 500;
        }

        span {
          font-size: 12px;
          display: block;
          font-weight: 400;
          color: $l-gray-2;
          @extend .tran;
        }

        &:not(:last-child) {
          border-right: 2px solid #f1f1f1;
        }
      }
    }
  }
}

.list-hover-arrow {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    a {
      font-size: 11px;
      color: $l-gray;
      position: relative;

      &:before {
        content: "\f04b";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-size: 6px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -10px;
        opacity: 0;
        visibility: hidden;
        @extend .tran;
      }

      &:hover {
        color: $red;
        padding-left: 10px;

        &:before {
          left: 0;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.star-rating {
  margin: 0 5px 0 0;
  padding: 0;
  list-style-type: none;

  li {
    display: inline-block;
    vertical-align: middle;
    color: $red;

    &:not(:last-child) {
      margin-right: 2px;
    }
  }
}

.two-col {
  column-count: 2;
}

.card- {
  &recommendation {
    padding: 35px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .image {
      flex: 0 0 70px;

      img {
        max-width: 100%;
        width: auto;
        height: auto;
      }
    }

    .about {
      flex: 0 0 calc(100% - 155px);
      margin: 0 15px;
    }

    .view-profile {
      flex: 0 0 55px;
    }
  }

  &reviews {
    &:not(:last-child) {
      margin-bottom: 30px;
    }

    a {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .image {
        flex: 0 0 60px;

        img {
          max-width: 100%;
          width: auto;
          height: auto;
          box-shadow: 0px 11px 21px 0px rgba(77, 77, 77, 0.22);
        }
      }

      .about {
        flex: 0 0 calc(100% - 75px);
        margin: 0 0 0 15px;
      }
    }
  }
}

.profile-top {
  background-color: #fbfbfb;
}

.list-tags {
  a {
    color: $d-gray;
    background-color: $light;
    font-size: 12px;
    border-radius: 50px;
    padding: 4px 8px;
  }
}

.list-dashed {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  li {
    font-size: 12px;
    flex: 0 0 50%;

    &:before {
      content: "-";
      color: $red;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
}

.rating-form {
  display: inline-block;
  position: relative;

  .star-wrap {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    margin-bottom: 0;
    cursor: pointer;

    &:last-child {
      position: static;

      .icon {
        color: $l-gray-2;
        @extend .tran;
      }
    }

    &:nth-child(1) {
      z-index: 5;
    }

    &:nth-child(2) {
      z-index: 4;
    }

    &:nth-child(3) {
      z-index: 3;
    }

    &:nth-child(4) {
      z-index: 2;
    }

    &:nth-child(5) {
      z-index: 1;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }

    .icon {
      float: left;
      color: transparent;
      line-height: 40px;
      height: auto;
      font-size: 40px;
      padding-left: 0;
      @extend .tran;

      &:before,
      &:after {
        content: none;
      }
    }
  }
}

.rating-form:not(:hover) .star-wrap input:checked ~ .icon,
.rating-form:hover .star-wrap:hover input ~ .icon {
  color: $red;
}

.rating-form .star-wrap input:focus:not(:checked) ~ .icon:last-child {
  color: $l-gray-2;
  text-shadow: 0 0 5px $red;
}

.rating-area-wrap {
  height: 100%;
  border-right: 1px solid #dee2e6;

  @include media-breakpoint-down(md) {
    border-right: 0;
  }

  .rating-form {
    & + .input-field label {
      font-size: 0.7rem;
    }
  }
}

.btn-rounded {
  border-radius: 50px;
  white-space: nowrap;
}

.list-property-stats {
  &.sm {
    li {
      .icon {
        background-image: url("../img/sprite-property-card-sm.png");
        width: 21px;
        height: 17px;

        &-2 {
          background-position: -31px 0;
        }

        &-3 {
          background-position: -61px 0;
        }
      }
    }
  }

  li {
    .icon {
      background-image: url("../img/sprite-property-card.png");
      background-repeat: no-repeat;
      display: inline-block;
      vertical-align: -3px;
      width: 27px;
      height: 19px;
      margin-right: 5px;

      &-1 {
        background-position: 0 0;
      }

      &-2 {
        background-position: -37px 0;
      }

      &-3 {
        background-position: -75px 0;
      }
    }
  }
}

.comparable-properties {
  .property {
    a {
      display: block;

      &:hover {
        @extend .bs-gray;
      }
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

.similar-properties {
  .property {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .image {
      flex: 0 0 115px;
    }

    .content {
      flex: 0 0 calc(100% - 135px);
    }
  }
}

#carousel-custom-dots {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    cursor: pointer;
  }
}
